import React, { Suspense, useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// Lazy load components
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const About = React.lazy(() => import("./components/pages/About"));
const Unitgrid = React.lazy(() => import("./components/pages/Unitgrid"));
const Unitgridleft = React.lazy(() => import("./components/pages/Unitgridleft"));
const Unitgridright = React.lazy(() => import("./components/pages/Unitgridright"));
const Unitdetails = React.lazy(() => import("./components/pages/Unitdetails"));
const Unitdetailsleft = React.lazy(() => import("./components/pages/Unitdetailsleft"));
const Unitdetailsright = React.lazy(() => import("./components/pages/Unitdetailsright"));
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloggridleft = React.lazy(() => import("./components/pages/Bloggridleft"));
const Bloggridright = React.lazy(() => import("./components/pages/Bloggridright"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
const Blogdetailsleft = React.lazy(() => import("./components/pages/Blogdetailsleft"));
const Blogdetailsright = React.lazy(() => import("./components/pages/Blogdetailsright"));
const Booking = React.lazy(() => import("./components/pages/Booking"));
const Faqs = React.lazy(() => import("./components/pages/Faqs"));
const Gallery = React.lazy(() => import("./components/pages/Gallery"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Errorpage = React.lazy(() => import("./components/pages/Errorpage"));

// Scroll to Top Component
const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ScrollToTop>
        <Preloader />
        <Routes>
          {/* Home */}
          <Route path="/" element={<Home />} />
          {/* About */}
          <Route path="/about" element={<About />} />
          {/* Gallery */}
          <Route path="/gallery" element={<Gallery />} />
          {/* Contact */}
          <Route path="/contact" element={<Contact />} />

          {/* Unit */}
          {/* Unit 
          <Route path="/unit/:minPrice/:maxPrice" element={<Unitgrid />} />
          <Route path="/unit-grid" element={<Unitgrid />} />
          <Route path="/unit-grid-left" element={<Unitgridleft />} />
          <Route path="/unit-grid-right" element={<Unitgridright />} />
          <Route path="/unit-details/:id" element={<Unitdetails />} />
          <Route path="/unit-details-left/:id" element={<Unitdetailsleft />} />
          <Route path="/unit-details-right/:id" element={<Unitdetailsright />} />
          */}
          {/* Blog */}
          {/*<Route path="/blog/cat/:catId" element={<Bloggrid />} />
          <Route path="/blog/tag/:tagId" element={<Bloggrid />} />
          <Route path="/blog/author/:authorId" element={<Bloggrid />} />
          <Route path="/blog/search/:query" element={<Bloggrid />} />
          <Route path="/blog-grid" element={<Bloggrid />} />
          <Route path="/blog-grid-left" element={<Bloggridleft />} />
          <Route path="/blog-grid-right" element={<Bloggridright />} />
          <Route path="/blog-details/:id" element={<Blogdetails />} />
          <Route path="/blog-details-left/:id" element={<Blogdetailsleft />} />
          <Route path="/blog-details-right/:id" element={<Blogdetailsright />} />
          */}
          {/* FAQ's */}
          {/* <Route path="/faqs" element={<Faqs />} />
          */}

          {/* Booking */}
          <Route path="/booking" element={<Booking />} />
          {/* Additional */}
          <Route path="/coming-soon" element={<Comingsoon />} />
          <Route path="/error-page" element={<Errorpage />} />
          <Route path="*" element={<Errorpage />} />
        </Routes>
      </ScrollToTop>
    </Suspense>
  );
}

export default App;
